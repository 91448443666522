import React from "react";
import axios from "axios";
import { toast } from "react-toastify";

type LoginType = {
	username: string;
	password: string;
};

export const checkLogin = (loginData: LoginType) => {
	return new Promise(async (resolve, reject) => {
		try {
			const LOGIN_URL = process.env.GATSBY_LOGIN_URL as string;
			const response = await axios.post(LOGIN_URL, loginData);
			return resolve(response);
		} catch (error: any) {
			console.error(error, "<<-- Error in login")
			toast.error("Invalid username or password");
			return reject(error);
		}
	});
};


// export const checkCustomLogin = (loginData: any) => {
// 	// console.log('token url', TOKEN_URL);
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const data = loginData;
// 			const TOKEN_URL = process.env.GATSBY_CUSTOM_LOGIN as string;
// 			const response = await axios.post(TOKEN_URL, data);
// 			return resolve(response);
// 		} catch (error: any) {
// 			toast.error("Invalid username or password");
// 			return reject(error);
// 		}
// 	});
// };
